import { isRouteErrorResponse, useRouteError } from 'react-router-dom';

import {
  GameException,
  NoUsernameFoundException,
  OnlyTelegramException,
  UserAccessException
} from '@/exceptions';

import {
  GameError,
  NotFound,
  NoUsernameFoundError,
  OnlyTelegramError,
  ServerError,
  UserAccessError
} from '../pages';

const Error = () => {
  const error = useRouteError();
  // eslint-disable-next-line no-console
  console.error(error);

  if (error instanceof GameException) {
    return <GameError />;
  }

  if (error instanceof UserAccessException) {
    return <UserAccessError />;
  }

  if (error instanceof OnlyTelegramException) {
    return <OnlyTelegramError />;
  }

  if (error instanceof NoUsernameFoundException) {
    return <NoUsernameFoundError />;
  }

  return isRouteErrorResponse(error) ? <NotFound /> : <ServerError />;
};

export default Error;
