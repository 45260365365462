import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { Bounce, ToastContainer } from 'react-toastify';

import {
  NoUsernameFoundException,
  OnlyTelegramException,
  UserAccessException
} from '@/exceptions';
import { useTelegram } from '@/hooks';
import { ignoreSwipe } from '@/libs';
import { BaseApi } from '@/redux/api';

import { Loading } from '../atoms';
import { BottomNav, Header } from '../organisms';

const Default = () => {
  const telegram = useTelegram();
  const navigate = useNavigate();
  const [auth, result] = BaseApi.useLazyAuthQuery();

  ignoreSwipe();

  useEffect(() => {
    const unsafeUser = telegram.initDataUnsafe.user;
    const schema = telegram.colorScheme;
    const isDark = schema === 'dark';

    (document.querySelector('html') as HTMLElement).dataset.theme = schema;

    telegram.BackButton.onClick(() => navigate(-1));
    telegram.setHeaderColor(isDark ? '#24272c' : '#d7d7d7');
    telegram.setBackgroundColor(isDark ? '#212227' : '#e3e3e3');
    telegram.enableClosingConfirmation();
    telegram.expand();
    telegram.ready();

    if (process.env.NODE_ENV === 'production') {
      if (telegram.platform === 'unknown') throw new OnlyTelegramException();

      if (!unsafeUser?.username) throw new NoUsernameFoundException();
    }

    const user = unsafeUser ?? {
      id: 648056940,
      first_name: '𝕸𝖆𝖍𝖉𝖎',
      last_name: '𝕾𝖆𝖒𝖆𝖛𝖎',
      username: 'samavi_dev',
      language_code: 'en',
      allows_write_to_pm: true
    };

    auth({
      ...user,
      telegram_id: user.id,
      device_id: 'application'
    });
  }, []);

  if (result.data?.active === false) {
    throw new UserAccessException();
  }

  return result.isSuccess ? (
    <>
      <Header />

      <main>
        <Outlet />
      </main>

      <BottomNav />

      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        stacked
        transition={Bounce}
        rtl
      />
    </>
  ) : (
    <Loading />
  );
};

export default Default;
